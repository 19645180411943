import moduleTag from './moduleTag'

export default {
  created () {
    if (!moduleTag.isRegistered) {
      this.$store.registerModule('tag', moduleTag)
      moduleTag.isRegistered = true
    }
  }
}
