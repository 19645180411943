<template>
  <vs-table :key="componentKey" :stripe="true" :data="tags" maxItems="25" pagination search class="vs-table-custom">
    <template class="flex" slot="header">
      <ValidationObserver v-slot="{ invalid, handleSubmit }">
        <form @submit.prevent="handleSubmit(addTag)">
          <ValidationProvider
            class="flex space-x-3 items-center"
            :name="$t('gallery.tag.table.header.add.tag')"
            rules="required|max:30"
            v-slot="{ errors }"
          >
            <vs-input
              :placeholder="$t('gallery.tag.table.header.add.tag')"
              v-model="tagInput"
            />
            <vs-button
              :disabled="invalid"
              button="submit"
              type="border"
              class="m-5 cursor-pointer"
              size="small"
              icon="add"
            />
            <span v-if="errors" class="error-class">{{ errors[0] }}</span>
          </ValidationProvider>
        </form>
      </ValidationObserver>
    </template>
    <template slot="thead">
      <vs-th sort-key="name">{{ $t('gallery.tag.table.theader.name') }}</vs-th>
      <vs-th sort-key="medias">{{ $t('gallery.tag.table.theader.medias')}}</vs-th>
      <vs-th>{{ $t("gallery.tag.table.theader.actions") }}</vs-th>
    </template>

    <template class="body-table-tags" slot-scope="{ data }">
      <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
        <vs-td class="tag-name" :data="data[indextr]">
          {{ data[indextr].name }}
        </vs-td>

        <vs-td :data="data[indextr]">
          {{ data[indextr].medias.length }}
        </vs-td>
        <vs-td :data="data[indextr]">
          <tags-action :data="data[indextr]" @updateRender="renderUpdate()" />
        </vs-td>
      </vs-tr>
    </template>
  </vs-table>
</template>

<script>
import moduleTagsMixin from '@/store/tag/moduleTagMixin'
import TagsAction from './TagsAction.vue'
export default {
  mixins: [moduleTagsMixin],
  components: {
    TagsAction
  },
  computed: {
    tags () {
      return this.$store.state.tag.tags
    }
  },
  data () {
    return {
      tagInput: '',
      componentKey: 0
    }
  },
  methods: {
    async fetchTags () {
      await this.$store.dispatch('tag/fetchTags')
    },
    async addTag () {
      await this.$store.dispatch('tag/addTag', { name: this.tagInput })
      this.tagInput = ''
    },
    renderUpdate () {
      this.componentKey++
    }
  },
  mounted () {
    this.fetchTags()
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped lang="scss">
.vs-table-custom {
  margin-top: -10px;
}
.multiselect {
  z-index: 999 !important;
}
.tag-name {
  font-weight: 500;
  color: rgba(var(--vs-dark), 1);
}
</style>
