var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vs-table',{key:_vm.componentKey,staticClass:"vs-table-custom",attrs:{"stripe":true,"data":_vm.tags,"maxItems":"25","pagination":"","search":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return _vm._l((data),function(tr,indextr){return _c('vs-tr',{key:indextr,attrs:{"data":tr}},[_c('vs-td',{staticClass:"tag-name",attrs:{"data":data[indextr]}},[_vm._v(" "+_vm._s(data[indextr].name)+" ")]),_c('vs-td',{attrs:{"data":data[indextr]}},[_vm._v(" "+_vm._s(data[indextr].medias.length)+" ")]),_c('vs-td',{attrs:{"data":data[indextr]}},[_c('tags-action',{attrs:{"data":data[indextr]},on:{"updateRender":function($event){return _vm.renderUpdate()}}})],1)],1)})}}])},[_c('template',{staticClass:"flex",slot:"header"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.addTag)}}},[_c('ValidationProvider',{staticClass:"flex space-x-3 items-center",attrs:{"name":_vm.$t('gallery.tag.table.header.add.tag'),"rules":"required|max:30"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{attrs:{"placeholder":_vm.$t('gallery.tag.table.header.add.tag')},model:{value:(_vm.tagInput),callback:function ($$v) {_vm.tagInput=$$v},expression:"tagInput"}}),_c('vs-button',{staticClass:"m-5 cursor-pointer",attrs:{"disabled":invalid,"button":"submit","type":"border","size":"small","icon":"add"}}),(errors)?_c('span',{staticClass:"error-class"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)]}}])})],1),_c('template',{slot:"thead"},[_c('vs-th',{attrs:{"sort-key":"name"}},[_vm._v(_vm._s(_vm.$t('gallery.tag.table.theader.name')))]),_c('vs-th',{attrs:{"sort-key":"medias"}},[_vm._v(_vm._s(_vm.$t('gallery.tag.table.theader.medias')))]),_c('vs-th',[_vm._v(_vm._s(_vm.$t("gallery.tag.table.theader.actions")))])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }