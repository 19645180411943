import axios from '@/axios'
import Vue from 'vue'

export default ({
  isRegistered: false,
  namespaced: true,
  state: {
    tags: [],
    totalTags: 0
  },
  mutations: {
    SET_TAGS (state, tags) {
      state.tags = tags['hydra:member']
      state.tags.sort((a, b) => {
        return a.name.localeCompare(b.name)
      })
      state.totalTags = tags.length
    },
    ADD_TAG (state, tag) {
      state.tags.push(tag)
      state.tags.sort((a, b) => {
        return a.name.localeCompare(b.name)
      })
      state.totalTags += 1
    },
    UPDATE_TAG (state, tagUpdated) {
      const tagIndex = state.tags.findIndex((tag) => tag.id === tagUpdated.id)
      Vue.set(state.tags, tagIndex, tagUpdated)
    },
    DELETE_TAG (state, tagId) {
      const tagIndex = state.tags.findIndex(t => t.id === tagId)
      state.tags.splice(tagIndex, 1)
      state.totalTags -= 1
    }
  },
  actions: {
    fetchTags ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('/gallery/tags')
          .then((response) => {
            commit('SET_TAGS', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    addTag ({ commit }, tag) {
      return new Promise((resolve, reject) => {
        axios.post('/gallery/tags', tag)
          .then((response) => {
            commit('ADD_TAG', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    updateTag ({ commit }, tag) {
      return new Promise((resolve, reject) => {
        axios.put(`/gallery/tags/${tag.id}`, tag)
          .then((response) => {
            commit('UPDATE_TAG', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    deleteTag ({ commit }, tagId) {
      return new Promise((resolve, reject) => {
        axios.delete(`/gallery/tags/${tagId}`)
          .then((response) => {
            commit('DELETE_TAG', tagId)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    }
  },
  getters: {}
})