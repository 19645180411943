<template>
  <div class="flex">
    <vs-tooltip :text="$t('gallery.tag.table.actions.search.tooltip')">
      <feather-icon
        icon="SearchIcon"
        svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"
        @click="onSearch"
      />
    </vs-tooltip>
    <feather-icon
      icon="Edit3Icon"
      svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"
      @click="onEdit"
    />
    <ValidationObserver v-slot="{ invalid }">
      <vs-prompt
        :title="$t('gallery.tags.table.actions.edit.dialog.title')"
        :accept-text="$t('gallery.tags.table.actions.edit.dialog.acceptText')"
        :active.sync="activePromptEditTag"
        :is-valid="!invalid"
        @accept="updateTag"
      >
        <ValidationProvider
          :name="$t('gallery.tags.table.actions.edit.dialog.label.name')"
          class="w-full block mb-5"
          rules="required|max:30"
          v-slot="{ errors }"
        >
          <small class="label-form">{{
            $t("gallery.tags.table.actions.edit.dialog.label.name")
          }}</small>
          <vs-input class="w-full" v-model="dataCopy.name"></vs-input>
          <span v-if="errors.length > 0" class="error-class">{{
            errors[0]
          }}</span>
        </ValidationProvider>
      </vs-prompt>
    </ValidationObserver>
    <feather-icon
      icon="Trash2Icon"
      svgClasses="h-5 w-5 hover:text-danger cursor-pointer"
      @click="confirmDeleteTag"
    />
  </div>
</template>

<script>
import moduleTagsMixin from '@/store/tag/moduleTagMixin'
export default {
  mixins: [moduleTagsMixin],
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      dataCopy: null,
      activePromptEditTag: false
    }
  },
  methods: {
    async updateTag () {
      await this.$store.dispatch('tag/updateTag', this.dataCopy)
    },
    onEdit () {
      this.activePromptEditTag = true
    },
    onSearch () {
      this.$router.push({
        name: 'gallery',
        params: {
          tag: this.data
        }
      })
    },
    confirmDeleteTag () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: this.$t('gallery.tags.table.actions.delete.dialog.title'),
        text: this.$t('gallery.tags.table.actions.delete.dialog.text', {
          name: this.dataCopy.name
        }),
        accept: this.deleteTag,
        acceptText: this.$t(
          'gallery.tags.table.actions.delete.dialog.acceptText'
        ),
        cancelText: this.$t(
          'gallery.tags.table.actions.delete.dialog.cancelText'
        )
      })
    },
    deleteTag () {
      this.$store
        .dispatch('tag/deleteTag', this.dataCopy.id)
        .then(() => {
          this.showDeleteSuccess()
          this.$emit('updateRender')
        })
        .catch(err => {
          console.error(err) // eslint-disable-line no-console
        })
    },
    showDeleteSuccess () {
      this.$vs.notify({
        color: 'success',
        text: this.$t('gallery.tags.table.actions.delete.notify.success.title')
      })
    }
  },
  created () {
    this.dataCopy = Object.assign({}, this.data)
  }
}
</script>

<style></style>
